#resume{
	width: 100%;
	height: auto;
}
.carosolContainer{
	display: flex;
	margin-top:3rem;
	overflow: hidden;
	width: 100%;
	height: 90%;
	background-color: var(--resumeCardBg);
	border-radius: 25px;
}
.resume-bullets {
	padding-right:0.5rem;
	margin-right: 10px;
	box-shadow: 10px 0px 9px -8px rgba(22, 22, 22, 0.849);
	width: 40%;
}
.bullet-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
}
.bullet-icons {
    width: 50px;
    height: 100%;
    z-index: 1;
    background-color: var(--resumeBulletBg);
    position: absolute;
	color: var(--bgColor);
	border-radius: 0 0 0 25px;
}
.bullets {
    width: 100%;
    position: relative;
    z-index: 2;
}
.bullet {
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: var(--resumeBulletBg);
    height: 45px;
    margin: 15px 0;
    padding: 0 15px;
    border-radius: 25px;
    width:0%;
    transition: width .6s ease;
	color:var(--textClr);
}
.bullet-logo {
	color: var(--bgColor);
	height: 16px;
    margin: 0 30px 0 0;
}
.bullets p {
    font-family: "Poppins SemiBold";
    white-space: nowrap;
	font-size: 18px;
	margin: 0 30px 0 0;
}
.animatedRowStyle{
	color: var(--bgColor)!important;
	width: 100%;
}
.bullets-details-Container{
	transition: all 1s ease-out;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 25rem;
	overflow: hidden;
	color: var(--textClr);
}
.common-lable-div{
	
	margin: 1.8rem 0;
	padding: 0 1.4rem;
}
.interests-block{
	margin: 1rem 0;
}
.headings{
	/* background-color: red; */
	display: flex;
	justify-content: space-between;
}
.common-lable-div .headings .primaryPara,.interests-block .primaryPara,.skillBlock p{
	color: var(--dark-orange);
	font-weight: 700;
	font-size: 1.1rem;
	letter-spacing: 0.3px;
	text-decoration: none;
}
.common-lable-div .headings .primaryPara:before,.interests-block .primaryPara:before{
	content:"🔴";
	margin-right: 14px;
	font-size:1rem;
	color: var(--dark-orange);	
}
.common-lable-div .secondaryPara,.interests-block .secondaryPara{
    margin-left: 42px;
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.2rem;
}
.nav-pills li{
	margin: 0.8rem 0;
	width: 100%;
}
.common-container{
	height: auto;	
	padding: 10px 0px;
}
.yearBtn{
	background-color: var(--dark-orange);
	width: auto;
	height:1.6rem;
	padding: 4px 8px;
	color: #fff;
	border-radius: 20px;
	font-weight: 400;
	font-size: 0.7rem;
}
.workHistoryParas{
	margin-left: 42px;
	font-size: 0.9rem;
}

.programingSkill-blocks-div {
	display: flex;
	margin: 1.2rem 0;
}
.programingSkill-blocks-div .skillBlock {
	width: 15rem;
	margin: 16px 30px;
}

/* ---------- Responsiveness --------------- */

@media screen and (max-width:1500px) {

	.carosolContainer{
		flex-direction: column;
		height: auto;
	}
	.workHistoryParas{
		font-size: 0.8rem;
		line-height: 1.1rem;
		margin: 0!important;
		margin-left: 66px;
		padding: 0 2rem;
	}
	.common-container{
		margin: 1rem 4px;
	}
	.common-lable-div{
		margin-top: 1rem;
	}
	.common-lable-div .headings .secondaryPara{
		font-size: 0.8rem;
	}
	.resume-bullets{
		width: 98%;
	}

	.bullets-details{
		margin-top: 2rem;
	}
	.yearBtn{
		width: auto;
	}
	.tab-pane,.common-container,.carosolContainer,.bullets-details-Container{
		height: auto!important;
	}
	
}

@media screen and (max-width:890px) {

	.common-lable-div .headings .primaryPara{
		color: var(--dark-orange);
		font-weight: 700;
		font-size: 0.8rem;
		letter-spacing: 0.2px;
		line-height: 0;
	}
	.common-lable-div .headings .secondaryPara{
		margin-left: 40px;
		font-size: 0.6rem;
		font-weight: 700;
		line-height: 0.8rem;
	}
	.yearBtn{
		background-color: var(--dark-orange);
		height: 1.3rem;
		padding: 6px 10px;
		color: #fff;
		border-radius: 20px;
		font-weight: 400;
		font-size: 0.5rem;
		width: auto;
		margin-left: auto;
	}
	.workHistoryParas{
		font-size: 0.61rem;
		line-height: 0.8rem;
		margin: 0!important;
		margin-left: 66px;
	}
    .common-lable-div{
		flex-direction: column;
		margin-top: 1rem;
    }
	.common-lable-div .headings .primaryPara:before{
		font-size:0.8rem;
	}
	.programingSkill-blocks-div .skillBlock {
		width: 10rem;
		margin: 8px 10px;
	}
	.bullets p {
		font-size: 16px;
	}
	.common-lable-div .secondaryPara,.interests-block .secondaryPara{
		margin-left: 40px;
		font-size: 0.7rem;
	}

}
@media screen and (max-width:400px){

	.common-lable-div .headings .primaryPara{
		font-size: 0.77rem;
		line-height: 0;
	}
	.common-lable-div .headings .secondaryPara{
		margin-left: 40px;
		font-size: 0.6rem;
		font-weight: 700;
		line-height: 0.9rem;
	}
	.yearBtn{
		background-color: var(--dark-orange);
		height: 1.3rem;
		padding: 6px 10px;
		color: #fff;
		border-radius: 20px;
		font-weight: 400;
		font-size: 0.5rem;
		width: auto;
		margin-left: auto;
	}
	.workHistoryParas{
		font-size: 0.61rem;
		line-height: 0.7rem;
		margin: 0!important;
		margin-left: 66px;
	}
	.programingSkill-blocks-div .skillBlock {
		width: 10rem;
		margin: 8px 10px;
	}
	.common-lable-div .headings .primaryPara:before{
		font-size:0.8rem;
	}
	.common-lable-div{
		margin-top: 1rem;
	}
	.common-lable-div{
		margin-top: 1rem;
		padding: 0;
	}
	.common-lable-div .secondaryPara,.interests-block .secondaryPara{
		margin-left: 40px;
		font-size: 0.7rem;
	}
	.common-lable-div .headings .primaryPara:before,.interests-block .primaryPara:before{
		content:"🔴";
		margin-right: 6px;
		font-size:0.8rem;
		color: var(--dark-orange);	
	}
	.interests-block .secondaryPara{
		margin-left: 20px;
	}
	.programingSkill-blocks-div {
		flex-direction: column;
	}
	.programingSkill-blocks-div .skillBlock {
		width: 80%;
	}

}
