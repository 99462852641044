#profile{
	background: var(--profileBg);
}
.profile-container{
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	height:65vh;
}

/* ------- For Dark Mode css --------- */

.profile-parent{
	display: flex;
	align-items: center;
}
.profile-details{
    margin-top: 0.6rem;
}
.social-media-icons i{
	font-size: 1.2rem;
    color: var(--socialMediaIconClr);
	/* background-color: rgb(22, 16, 16); */
    padding: 9px;
    padding-left: 1px;
}
.social-media-icons i:hover{
	transform: scale(1.5);
}
.profile-details-name{
	font-size: 1.5rem;
	font-family: "Poppins SemiBold";
	margin-top: 0.3rem;
}
.profile-details-role{
	display: flex;
	flex-direction: column;
	margin: 	0.875rem 0 1.5rem 0;
}
.profile-details-role .typical-text{
	color:var(--typicalTextClr);
	font-size: 2.4rem;
	line-height: 0.2rem;
	font-family: "Balsamiq Sans";
}
.styles_typicalWrapper__1_Uvh{
	margin-top: 1.5rem;
}
.primary-text{
	color: var(--primaryTextClr);
}
.highlighted-text{
	color: var(--highlightedTextClr);
}
.profile-role-description{
	color: var(--profileDescription);
	font-size: 1.12rem;
	font-family: "Poppins Light";
	margin: 3rem 2rem 1rem 2rem;
}
.profile-options {
	text-align: center;
}
.btn {
	border-radius: 50px;
	padding: 0.7rem 0!important;
	width: 160px;
	cursor: pointer;
	transition: 0.2s;
	font-family: "Poppins SemiBold";
}
.btn:active {
	transform: translateY(2px);
}
.primary-btn {
	font-size: 0.8rem!important;
	color: var(--white)!important;
	border: 2px solid linen!important;
	background-color: #1f2235!important;
	border-radius: 25px!important;
}
.primary-btn:hover {
	color: aliceblue!important;
	border: 2px solid #ff5823!important;
	background-color: #1f2235!important;
}
  
.highlighted-btn {
	border-radius: 25px!important;
	margin-left:1.75rem!important;
	font-size: 0.8rem!important;
	color: var(--white)!important;
	background-color: #ff5823!important;
}
.highlighted-btn:hover {
	border: 2px solid #ff5823!important;
	color: #ff5823!important;
	background-color: cornsilk!important;
}
.profile-picture{
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 1px 0px 0px var(--profilePicShadowClr);
	border-radius: 50%;
	height: 420px; 
	width: 420px;
	margin: 0 0px 0 80px;
}
.profile-picture-background{
	height: 92%;
	width: 92%;
	background-image: url('../../assets/Home/profilephoto.jpg');
	background-size: cover;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-position: center;
}
.profile-picture-background:hover{
	transform: scale(1.07);
	transition: 1s ease-out;
}

/* -------------------------------- Responsivness ----------------------------------------- */

@media screen and (max-width:1110px) {
	.profile-role-description{
		font-size: 0.8rem;
	}
	.profile-details-role .typical-text{
		font-size: 1.9rem;
		line-height: 0.2rem;
	}
	.primary-text{
		font-size: 1.1rem;
	}
	.profile-picture{
		width: 350px;
		height: 350px;	
		margin: 0 0.5rem 0 2rem;
	}
	.profile-picture-background{
		height: 90%;
		width: 90%;
	}
	.profile-options .primary-btn,.highlighted-btn{
        width: 6.2rem!important;
		height: auto!important;
	}
}
@media screen and (max-width:910px) {
	.profile-role-description{
		font-size: 0.7rem;
	}
	.profile-details-role .typical-text{
		font-size: 1.8rem;
		line-height: 0.2rem;
	}
	.primary-text{
		font-size: 1.1rem;
	}
	.profile-picture{
		height: 310px; 
		width: 310px;
		margin: 0 0px 0 4px;
	}
	.profile-picture-background{
		height: 88%;
		width: 88%;
	}	
	.profile-details{
		padding: 1rem 0 ;
	}
}
@media screen and (max-width:810px) {
	.profile-parent{
        flex-direction: column-reverse;
	}
	.profile-details-role .typical-text{
		font-size: 1.4rem;
		margin: 0 0.6rem;
	}
	.primary-text{
		font-size: 1rem;
	}
	.profile-role-description {
		font-size: 0.7rem;
		margin: 1.2rem 0.6rem 0 0.6rem;
	}
	.profile-picture{
		border-radius: 50%;
		height:45vw; 
		width: 45vw;
		margin: 0 ;
	}
	.profile-details{
		padding-top: 0;
	}
	.profile-container
	{
		padding-top: 0;
	}
	.styles_typicalWrapper__1_Uvh{
		margin-top: 0.6rem;
	}

}
@media screen and (max-width:375px) {
	.profile-details-role .typical-text{
		font-size: 1.1rem;
		margin: 0 0.6rem;
	}
	.primary-text{
		font-size: 0.8rem;
	}
	.profile-role-description {
		font-size: 0.6rem;
		margin: 0rem 0.6rem 0 0.6rem;
	}	
	.profile-options .primary-btn,.highlighted-btn{
        width: 5.8rem!important;
		height: auto!important;
        padding: 0.4rem 0 !important;
	    font-size: 0.76rem!important;
	}
	.profile-details{
		margin-top: 15px;
	}
	.profile-picture{
		height: 9rem; 
		width: 9rem;
	}
	.highlighted-btn{
		margin-left:1rem!important;
	}
	  
}
@media screen and (max-width:330px) {
	.profile-picture{
		height: 7rem; 
		width: 7rem;
	}
	.profile-details{
		margin-top: 14px;
	}
	.styles_typicalWrapper__1_Uvh{
		margin-top: 0;
	}
	.profile-options .primary-btn,.highlighted-btn{
		width: 5rem!important;
		height: auto!important;
		padding: 0.3rem 0 !important;
		font-size: 0.6rem!important;
	}
	.profile-details-role .typical-text{
		font-size: 1rem;
		line-height: 0.2rem;
	}
}