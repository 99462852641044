#contact{
    width: 100%;
    height:auto;
	margin: 4rem 0;
}
.contact-parenet{
    display: flex;	
	border-radius: 25px;
	box-shadow:var(--boxShadow);
	margin: 2rem 0;
	overflow: hidden;
}
.contact-parenet p{
	color: var(--textClr)!important;
}
/* ----- contact-info-sec css ---------- */

.contact-info-sec{
	width: 100%;
	background-color:var(--contactCardLeftBg);
}
.info{
	margin-top: 3.6rem;
}
.info a{
	word-break: break-all;
}
.contact-info{
	padding: 0.77rem 2rem;
	position: relative;
}
.icon{
	font-size: 1.7rem;
	color: #1abc9c;
	margin-right: 0.7rem;
}
.contact-info .title{
	color: #1abc9c;
}
.text{
	color: #333;
	margin: 1.5rem 0 2rem 0;
}
.information{
	display: flex;
	margin: 0.7rem 0;
	align-items: center;
	font-size: 0.95rem;
}
.information a{
	text-decoration: none;
	color: var(--contactInfoTextClr);
}
.information a:hover{
	color: #8a8b8b;
}
.social-icons-container{
	display: flex; 
    margin-top: 0.5rem;
}
.social-media{
	padding: 3rem 0  0 0;
}
.social-media p{
	color: #333;
}
.social-icon{
	width: 40px;
	height: 40px;
	margin-right: 0.7rem;
}
.social-icons-container a{
	display: inline-block;
	width: 35px;
	height: 35px;
	border-radius: 5px;
	padding: 2px 4px;
	background:linear-gradient(45deg,#1abc9c,#149279);
	color: #fff!important;
	text-align: center;
	line-height: 35px;
    transition: 0.3s;
}
.social-icons-container a:hover{
	transform: scale(1.15);
}
.contact-info:before{
	content: "";
	position: absolute;
	width: 170px;
	height: 170px;
	border: 22px solid #1abc9c;
	border-radius: 50%;
	bottom: -120px;
	right: 60px;
	opacity: 0.3;
}

/* ------- contact-form-sec css -------------- */

.contact-form-sec{
	width: 100%;
	background-color: #1abc9c;
	position: relative;
}
.circle{
	width: 100px;	
	z-index: 4000;
	height: 100px;
	border-radius: 50%;
	position: absolute;
	background: linear-gradient(135deg,transparent 20%,#149279);
}
.circle.one{
	width: 130px;
	height: 130px;
	top: 130px;
    right:-40px;
}
.circle.two{
	width: 80px;
	height: 80px;
	top: 10px;
	right: 30px
} 
.contact-form-sec:before{
	content: "";
	position: absolute;
	width: 26px;
	height: 26px;
	background-color: #1abc9c;
	transform: rotate(45deg);
	top: 50px;
	left: -13px;
}
form{
    padding: 0.77rem 2rem;
}
.title{
	color: #fff;
	font-weight: 500;
	font-size: 1.5rem;
	margin-bottom: 0.7rem;
}
.input-container{
	position: relative;
	margin: 1rem  0;
}
.input{
	width: 100%;
	outline: none;
	border: 2px solid #fafafa;
	background:none;
	padding: 0.4rem 1.2rem;
	color: #fff;
	font-weight: 500;
	font-size: 0.95rem;
	letter-spacing: 0.5px;
	border-radius: 25px;
	transition: 0.3s;
}
textarea.input{
	padding: 0.4rem 1.2rem;
	min-height: 150px;
	border-radius: 22px;
	resize: none;
	overflow-y:auto;
}
.input-container label{
	position: absolute;
	top:50%;
	left: 15px;
	transform: translateY(-50%);
	padding: 0 .4rem;
	color: #fafafa;
	font-size: 0.9rem;
	font-weight: 400;
	pointer-events: none;
	z-index:1000;
	transition: 0.5rem;
}
.input-container.textarea label{
	top:1rem;
	transform: translateY(0);
}
.sendBtn{
	padding: 0.4rem 1rem;
	background-color: #fff;
	border: 2px solid #fafafa;
	font-weight: 700;
	font-size: 0.95rem;
	color: #1abc9c;
	line-height: 1;
	border-radius: 25px;
	outline: none;
	cursor:pointer;
	transition: 0.3s;
	margin: 0;
	box-shadow: 0 0 10px 1px #085848;
}
.sendBtn:hover{
	background-color: transparent;
	color: #fff;
}
.input-container span{
	position: absolute;
	top: 0;
	left: 25px;
	transform: translateY(-50%);
	font-size: 0.8rem;
	padding: 0 0.4rem;
	color: transparent;
	pointer-events: none;
	z-index: 500;
}
.input-container span:before,
.input-container span:after{
    content: "";
    position: absolute;
    height: 50%;
    width: 0%;
    opacity: 0;
    transition: 0.3s;
    background-color: #1abc9c;
    top: 50%;
    transform: translateY(-50%); 
}
.input-container span:before{
	left: 50%;
}
.input-container span::after{
	right: 50%;
}
.input-container.focus label{
	top:0;
	left: 25px;
	transform: translateY(-50%);
	font-size: 0.8rem;
}
.input-container.focus span:before,
.input-container.focus span:after{
    width: 50%;
    opacity: 1;
}

/* ----------- Responsivness ------------- */

@media (max-width:1000px){
	
	.contact-parenet{
		flex-direction: column;
	}
   .text{
		 margin: 1rem 0 1.5rem 0;
	 }
   .social-media{
		 padding:1.5rem 0 0 0 
	 }
}

@media (max-width:570px){
	.contact-info{
		padding: 1.8rem 1.4rem;
	}
	form{
		padding: 1.8rem  1.4rem;
	}
	.text{
	  margin: 0.4rem 0 0.6rem 0;
	}
	.social-media{
		padding:1.7rem 0 0 0 
	}
	.info{
		margin-top: 1.8rem;
	}
	.information i{
		font-size: 1.5rem;
		font-size: 1.3rem;
	}
	.information{
		margin: 0.1rem 0;
	}
	.social-icons-container a{
		transform: scale(0.7);
	}
	.social-media p{
		line-height: 0;
	}
	
}
@media (max-width:416px){
	.contact-info .text,.information,.input{
		font-size: 0.8rem;
	}
}
@media (max-width: 330px){
    .contact-info .text,.information{
 	    font-size: 0.7rem;
    }
	.input,.sendBtn{
		font-size: 0.7rem;
	}
    .title{
		font-size: 1.30rem;
	}
}